const target = document.querySelector('.js-fixed-otaLink-close');

const panel = document.querySelector('.c-fixed-otaLink');

window.addEventListener("scroll", ()=> {
  if(window.scrollY > 500) {
    panel.classList.add("is-show");
  }
  if(window.scrollY < 500) {
    panel.classList.remove("is-show");
  }
})

target.addEventListener("click", ()=> {
  panel.classList.add("is-remove");
})