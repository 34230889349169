
import Swiper from 'swiper';


//const swiperSlides3 = document.getElementsByClassName('swiper-slide');
const breakPoint = 1200;
let mySwiper ;
let mySwiperBool ;
let mySwiper2 ;
let mySwiperBool2 ;
let mySwiper3 ;
let mySwiperBool3 ;

/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  if( breakPoint < window.innerWidth){
    mySwiperBool = false;
    mySwiperBool2 = false;
    mySwiperBool3 = false;
  } else {
    if(document.querySelector('.js-nikko') || document.querySelector('.js-nasu') || document.querySelector('.js-reccomend'))
    createSwiper();
    createSwiper2();
    createSwiper3();
    mySwiperBool = true;
    mySwiperBool2 = true;
    mySwiperBool3 = true;
  }
},false);

/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{
  if( breakPoint < window.innerWidth && mySwiperBool){
    mySwiper.destroy(false,true);
    mySwiperBool = false;
  }else if( breakPoint >= window.innerWidth && !(mySwiperBool)){
    createSwiper();
    mySwiperBool = true;
  }

  if( breakPoint < window.innerWidth && mySwiperBool2){
    mySwiper2.destroy(false,true);
    mySwiperBool2 = false;
  }else if( breakPoint >= window.innerWidth && !(mySwiperBool2)){
    createSwiper2();
    mySwiperBool2 = true;
  }

  if( breakPoint < window.innerWidth && mySwiperBool3){
    mySwiper3.destroy(false,true);
    mySwiperBool3 = false;
  }else if( breakPoint >= window.innerWidth && !(mySwiperBool3)){
    createSwiper3();
    mySwiperBool3 = true;
  }
},false);




/*------------------------------
日光スライド
-------------------------------*/
const createSwiper = () =>{
  mySwiper = new Swiper ('.js-nikko', {
      slidesPerView: 4,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      loop: true,
      onSlideChangeEnd : (s) => {s.fixLoop();},
      spaceBetween: 40,
      loopedSlides: 4,
      centeredSlides : true,
      disableOnInteraction: true,
      slideToClickedSlide: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true 
      },
      breakpoints: {
        768: {
          //slidesPerView: 1,
          centeredSlides : true,
          spaceBetween: 20,
        }
      }
  });
}

/*------------------------------
那須スライド
-------------------------------*/
const createSwiper2 = () =>{
  mySwiper2 = new Swiper ('.js-nasu', {
    slidesPerView: 4,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    loop: true,
    onSlideChangeEnd : (s) => {s.fixLoop();},
    spaceBetween: 40,
    loopedSlides: 4,
    centeredSlides : true,
    disableOnInteraction: true,
    slideToClickedSlide: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true 
    },
    breakpoints: {
      768: {
        //slidesPerView: 1,
        centeredSlides : true,
        spaceBetween: 20,
      }
    }
  });
}

/*------------------------------
おすすめスポットスライド
-------------------------------*/
const createSwiper3 = () =>{
  mySwiper3 = new Swiper ('.js-reccomend', {
      slidesPerView: 4,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      loop: true,
      onSlideChangeEnd : (s) => {s.fixLoop();},
      spaceBetween: 40,
      loopedSlides: 4,
      disableOnInteraction: true,
      slideToClickedSlide: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true 
      },
      breakpoints: {
        768: {
          //slidesPerView: 1,
          centeredSlides : true,
          spaceBetween: 20,
        }
      }
    });
}

