
/*--------------------------------
//SNSシェアボタン色変更
--------------------------------*/
var thisOffset;
$(window).on('load', function () {
  //thisOffset = $('.section-hero__inner').offset().top + $('.section-hero__inner').outerHeight();
  thisOffset = 80;
});

$(window).scroll(function () {
  if ($(window).scrollTop()  > thisOffset) {
    // $('.l-header__inner').addClass('-change');
    // $('.l-header-share').addClass('-change');
    // $('.l-header-sns__link-facebook').addClass('-change');
    // $('.l-header-sns__link-twitter').addClass('-change');
    $('#js-fullScreenMenu').addClass('-change-full');
    $('.l-header-share').addClass('-change-full');
    $('.l-header-sns__link-facebook').addClass('-change-full');
    $('.l-header-sns__link-twitter').addClass('-change-full');
    $('.fullScreenMenu-hamburger').addClass('-change-full');
    
  } else {
    // $('.l-header__inner').removeClass('-change');
    // $('.l-header-share').removeClass('-change');
    // $('.l-header-sns__link-facebook').removeClass('-change');
    // $('.l-header-sns__link-twitter').removeClass('-change');
    $('#js-fullScreenMenu').removeClass('-change-full');
    $('.l-header-share').removeClass('-change-full');
    $('.l-header-sns__link-facebook').removeClass('-change-full');
    $('.l-header-sns__link-twitter').removeClass('-change-full');
    $('.fullScreenMenu-hamburger').removeClass('-change-full');
  }
});



/*--------------------------------
//上部へ戻る
--------------------------------*/
//スクロール量を取得する関数
function getScrolled() {
  return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
}
        
//トップに戻るボタンの要素を取得
var topButton = document.getElementById( 'js-scroll-fadein' );
          
//ボタンの表示・非表示
window.onscroll = function() {
  ( getScrolled() > 500 ) ? topButton.classList.add( 'is-fadein' ): topButton.classList.remove( 'is-fadein' );
};
          
//トップに移動する関数
function scrollToTop() {
  var scrolled = getScrolled();
  window.scrollTo( 0, Math.floor( scrolled / 2 ) );
  if ( scrolled > 0 ) {
    window.setTimeout( scrollToTop, 30 );
  }
};
          
//イベント登録
topButton.onclick = function() {
  scrollToTop();
};