import swiper from 'swiper';

/*---------------------------------
日光モーダル設定
----------------------------------*/
var topPoint = new swiper('.js-modal-nikko', {
  effect: "horizontal",
  speed: 600,
  centeredSlides: true,
  slidesPerView: "auto",
  // autoplay: {
  //   delay: 5500,
  //   disableOnInteraction: false,
  // },
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


/*---------------------------------
那須モーダル設定
----------------------------------*/
var topPoint2 = new swiper('.js-modal-nasu', {
  effect: "horizontal",
  speed: 600,
  centeredSlides: true,
  slidesPerView: "auto",
  // autoplay: {
  //   delay: 5500,
  //   disableOnInteraction: false,
  // },
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


/*---------------------------------
おすすめスポットモーダル設定
----------------------------------*/
var topPoint3 = new swiper('.js-modal-reccomend', {
  effect: "horizontal",
  speed: 600,
  centeredSlides: true,
  slidesPerView: "auto",
  // autoplay: {
  //   delay: 5500,
  //   disableOnInteraction: false,
  // },
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});



/*---------------------------------
日光モーダル実行
----------------------------------*/
let pointItems = document.querySelectorAll('.js-nikko .c-slide-item');

pointItems.forEach((pointItem, index) => {
  // pointItem.classList.add("dd")
  pointItem.addEventListener('click', (e) => {
    //console.log(`${index}: ${pointItem}`)
    topPoint.slideTo(index, 0);
  })
})



/*---------------------------------
那須モーダル実行
----------------------------------*/
let pointItems2 = document.querySelectorAll('.js-nasu .c-slide-item');

pointItems2.forEach((pointItem2, index) => {
  // pointItem.classList.add("dd")
  pointItem2.addEventListener('click', (e) => {
    //console.log(`${index}: ${pointItem}`)
    topPoint2.slideTo(index, 0);
  })
})


/*---------------------------------
おすすめスポットモーダル実行
----------------------------------*/
let pointItems3 = document.querySelectorAll('.js-reccomend .c-slide-item');

pointItems3.forEach((pointItem3, index) => {
  // pointItem.classList.add("dd")
  pointItem3.addEventListener('click', (e) => {
    //console.log(`${index}: ${pointItem}`)
    topPoint3.slideTo(index, 0);
  })
})









